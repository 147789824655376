import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

import { useAuth } from '../auth/authProvider'
import { FAILED, SUCCESS, useApi } from './useApi'
import {
  listEnterprises,
  loadEnterprise,
  addEnterprise,
  updateEnterprise,
  loadEnterpriseSubscription,
  loadEnterpriseAccess,
  updateAccess
} from '../redux/ducks/enterprise'
import { setApiErrorMsg, setApiSuccessMsg } from '../redux/ducks/notification'
import { createEnterpriseAccess } from '../utils/api/sigyn'

const enterpriseAttributes = (enterprise) => ({
  id: enterprise.id,
  ownerId: enterprise.attributes.ownerId,
  type: enterprise.attributes.type,
  name: enterprise.attributes.name,
  code: enterprise.attributes.code,
  connection: enterprise.attributes.connection,
  seats: enterprise.attributes.seats,
  domains: enterprise.attributes.domains,
  users: enterprise.attributes.users,
  hubSpotId: enterprise.attributes.hubSpotId,
  createdAt: enterprise.attributes.createdAt,
  updatedAt: enterprise.attributes.updatedAt
})

const enterpriseSubscriptionAttributes = (subscription) => ({
  id: subscription.id,
  status: subscription.attributes.status,
  autoRenew: subscription.attributes.autoRenew,
  origin: subscription.attributes.origin,
  startsAt: subscription.attributes.startsAt,
  endsAt: subscription.attributes.endsAt,
  createdAt: subscription.attributes.createdAt,
  updatedAt: subscription.attributes.updatedAt
})

const enterpriseAccessAttributes = (access) => ({
  id: access.id,
  provider: access.attributes.provider,
  startsAt: access.attributes.startsAt,
  createdAt: access.attributes.createdAt,
  endsAt: access.attributes.endsAt,
  subscriptionId: access.attributes.subscriptionId,
  updatedAt: access.attributes.updatedAt
})

const useListEnterprises = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [requestEnterprise, responseData] = useApi()

  useEffect(() => {
    const {
      state, data, error, status
    } = responseData
    if (state === SUCCESS) {
      if (data?.data.length) {
        const enterprises = data
        dispatch(listEnterprises(enterprises))
      } else {
        dispatch(listEnterprises({ data: [], meta: { total: 0 } }))
      }
    } else if (state === FAILED) {
      if (!status) {
        history.push('/500')
      }
      dispatch(setApiErrorMsg(`Unable to fetch enterprises: ${error} ${status}`))
    }
  }, [responseData, dispatch, history])

  return [requestEnterprise, responseData]
}

const useAddEnterprise = () => {
  const [requestEnterprise, responseData] = useApi()
  const [requestEnterpriseAccess] = useApi()
  const dispatch = useDispatch()
  const { user: authUser } = useAuth()

  useEffect(() => {
    const {
      state, data, error, status
    } = responseData
    if (state === SUCCESS) {
      const enterprise = data.data

      // grant enterprise access after enterprise is created
      const payload = { product: 'BI Prime' }
      requestEnterpriseAccess(createEnterpriseAccess(authUser, enterprise.id, payload))

      dispatch([
        addEnterprise(enterpriseAttributes(enterprise)),
        setApiErrorMsg(''),
        setApiSuccessMsg('ok')
      ])
    } else if (state === FAILED) {
      dispatch(setApiSuccessMsg(''))
      if (!status) {
        dispatch(setApiErrorMsg(`Unable to create enterprise: ${error}. Please try again later`))
      } else {
        dispatch(setApiErrorMsg(`Unable to create enterprise: ${error} ${status}`))
      }
    }
  }, [responseData, dispatch, requestEnterprise, requestEnterpriseAccess, authUser])

  return [requestEnterprise, responseData]
}

const useLoadEnterprise = () => {
  const [requestEnterprise, responseData] = useApi()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const {
      state, data, error, status
    } = responseData
    if (state === SUCCESS) {
      if (_.isEmpty(data.data)) {
        dispatch(setApiErrorMsg('This enterprise is not found'))
      } else {
        const enterprise = _.isArray(data.data) ? data.data[0] : data.data
        dispatch(loadEnterprise(enterpriseAttributes(enterprise)))
      }
    } else if (state === FAILED) {
      if (!status) {
        history.push('/500')
      } else if (status === 404) {
        history.push('/enterprisenotfound')
      }
      dispatch(setApiErrorMsg(`Unable to load enterprise: ${error} ${status}`))
    }
  }, [responseData, dispatch, history])

  return [requestEnterprise, responseData]
}

const useUpdateEnterprise = () => {
  const [requestEnterprise, responseData] = useApi()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const {
      state, data, error, status
    } = responseData
    if (state === SUCCESS) {
      const enterprise = data.data
      dispatch([
        updateEnterprise(enterpriseAttributes(enterprise)),
        setApiErrorMsg(''),
        setApiSuccessMsg(`Enterprise ${enterprise.attributes.name} is updated!`)
      ])
    } else if (state === FAILED) {
      dispatch(setApiSuccessMsg(''))
      if (!status) {
        dispatch(setApiErrorMsg(`Unable to update enterprise: ${error}. Please try again later`))
      } else {
        dispatch(setApiErrorMsg(`Unable to update enterprise: ${error} ${status}`))
      }
    }
  }, [responseData, dispatch, history])

  return [requestEnterprise, responseData]
}

const useLoadEnterpriseSubscription = () => {
  const [requestEnterprise, responseData] = useApi()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const {
      state, data, error, status
    } = responseData
    if (state === SUCCESS) {
      if (_.isEmpty(data.data)) {
        dispatch(loadEnterpriseSubscription({}))
      } else {
        const sub = data.data[0]
        dispatch(loadEnterpriseSubscription(enterpriseSubscriptionAttributes(sub)))
      }
    } else if (state === FAILED) {
      if (!status) {
        history.push('/500')
      } else if (status === 404) {
        history.push('/enterprisenotfound')
      }
      dispatch(setApiErrorMsg(`Unable to load enterprise: ${error} ${status}`))
    }
  }, [responseData, dispatch, history])

  return [requestEnterprise, responseData]
}

const useLoadEnterpriseAccess = () => {
  const [requestEnterprise, responseData] = useApi()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const {
      state, data, error, status
    } = responseData
    if (state === SUCCESS) {
      if (_.isEmpty(data.data)) {
        dispatch(loadEnterpriseAccess({}))
      } else {
        const access = data.data[0]
        dispatch(loadEnterpriseAccess(enterpriseAccessAttributes(access)))
      }
    } else if (state === FAILED) {
      if (!status) {
        history.push('/500')
      } else if (status === 404) {
        history.push('/enterprisenotfound')
      }
      dispatch(setApiErrorMsg(`Unable to load enterprise access: ${error} ${status}`))
    }
  }, [responseData, dispatch, history])

  return [requestEnterprise, responseData]
}

const useUpdateEnterpriseAccess = () => {
  const [requestAccess, responseData] = useApi()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const {
      state, data, error, status
    } = responseData
    if (state === SUCCESS) {
      const access = data.data
      dispatch([
        updateAccess(enterpriseAccessAttributes(access)),
        setApiErrorMsg(''),
        setApiSuccessMsg('Enterprise access is updated!')
      ])
    } else if (state === FAILED) {
      dispatch(setApiSuccessMsg(''))
      if (!status) {
        dispatch(setApiErrorMsg(`Unable to update enterprise's access: ${error}. Please try again later`))
      } else {
        dispatch(setApiErrorMsg(`Unable to update enterprise's access: ${error} ${status}`))
      }
    }
  }, [responseData, dispatch, history])

  return [requestAccess, responseData]
}

export {
  useListEnterprises,
  useAddEnterprise,
  useLoadEnterprise,
  useUpdateEnterprise,
  useLoadEnterpriseSubscription,
  useLoadEnterpriseAccess,
  useUpdateEnterpriseAccess
}
