import React, { useState, useEffect } from 'react'

import { Button } from 'reactstrap'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Loading from '../Shared/Loading'

import Select from '../Form/Select'
import { useUpdateUser } from '../../hooks/useUser'
import { useAuth } from '../../auth/authProvider'
import { updateUserEnterprise, updateUser } from '../../utils/api/skadi'

const AddEnterprise = ({ setShowAddEnterpriseToUser }) => {
  const { user: authUser } = useAuth()

  const [requestUpdateUser] = useUpdateUser()

  const {
    user: { profile },
    enterprise: { enterprises: { records } }
  } = useSelector((state) => state)

  const [enterpriseNames, setEnterpriseNames] = useState([])
  const [enterpriseSelected, setEnterpriseSelected] = useState('')

  useEffect(() => {
    if (records) {
      setEnterpriseNames(records.map((record) => record.attributes.name))
    }
  }, [authUser, records])

  const setOptions = () => {
    const options = enterpriseNames.sort()
    return ['Select Enterprise', ...options]
  }

  const handleEnterpriseDropdownChange = (e) => {
    const { value } = e.target
    setEnterpriseSelected(value)
  }

  const handleSaveEnterprise = async () => {
    if (!enterpriseSelected) {
      return
    }

    const enterprise = records.find((record) => record.attributes.name === enterpriseSelected)
    if (!enterprise) {
      return
    }

    const updateUserEnterprisePayload = {
      enterpriseId: enterprise.id
    }

    const updateUserPayload = {
      emailVerifiedAt: new Date()
    }

    requestUpdateUser(updateUserEnterprise(authUser, profile.id, updateUserEnterprisePayload))
    requestUpdateUser(updateUser(authUser, profile.id, updateUserPayload))
  }

  const handleCancelSaveEnterprise = () => {
    setEnterpriseSelected('')
    setShowAddEnterpriseToUser(false)
  }

  if (!profile) {
    return <Loading />
  }

  return (
    <>
      <Select
        className="form-input enterprise-select"
        name="name"
        options={setOptions}
        value={enterpriseSelected}
        onChange={handleEnterpriseDropdownChange}
        hideLabel
      />
      <Button
        color="success"
        size="sm"
        type="button"
        className="small-btn small-margin-btn"
        onClick={handleSaveEnterprise}
      >
        Save
      </Button>

      <Button
        color="secondary"
        size="sm"
        type="button"
        className="small-btn"
        onClick={handleCancelSaveEnterprise}
      >
        Cancel
      </Button>
    </>
  )
}

AddEnterprise.defaultProps = { setShowAddEnterpriseToUser: () => {} }

AddEnterprise.propTypes = { setShowAddEnterpriseToUser: PropTypes.func }

export default AddEnterprise
