import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

const Select = React.forwardRef(({
  className, label, name, options, value, onChange, hideLabel
}, ref) => {
  const optionList = _.isArray(options) ? options : options()

  return (
    <div className="form-el-wrapper">
      <label className="form-label" style={hideLabel ? { display: 'none' } : { display: 'inline-block' }}>{label}</label>
      <div className="form-input-wrapper">
        <select
          className={className}
          name={name}
          onChange={onChange}
          value={value}
          ref={ref}
        >
          {optionList.map((optionValue) => (
            <option key={optionValue} value={optionValue}>
              {optionValue}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
})

Select.defaultProps = {
  className: '',
  label: '',
  name: '',
  options: [],
  value: '',
  hideLabel: false,
  onChange: () => {}
}

Select.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  hideLabel: PropTypes.bool,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.func])
}

export default Select
