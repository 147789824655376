import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Button } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Loading from '../Shared/Loading'
import Input from '../Form/Input'
import Select from '../Form/Select'

import { useAuth } from '../../auth/authProvider'
import { usePrevious } from '../../utils/customHooks'
import { useLoadUser, useUpdateUser } from '../../hooks/useUser'
import { resetAllMessages, setApiSuccessMsg } from '../../redux/ducks/notification'
import { getUser, updateUser } from '../../utils/api/skadi'
import { setFormError } from '../../utils/format'

const EditProfile = ({ setIsEditUser }) => {
  const { user: authUser } = useAuth()
  const { id } = useParams()
  const dispatch = useDispatch()
  const { register, handleSubmit, errors } = useForm()

  const [requestLoadUser] = useLoadUser()
  const [requestEditUser, userResponse] = useUpdateUser()

  const { profile } = useSelector((state) => state.user)

  const [user, setUser] = useState(profile)

  useEffect(() => {
    dispatch(resetAllMessages())
  }, [dispatch])

  useEffect(() => {
    setUser(profile)
  }, [profile])

  useEffect(() => {
    requestLoadUser(getUser(authUser, id))
  }, [authUser, id, requestLoadUser])

  const { email, type } = profile
  const firstname = profile.firstname || ''
  const lastname = profile.lastname || ''
  const emailVerifiedAt = profile.emailVerifiedAt || ''

  const prevUserData = usePrevious({
    firstname, lastname, email, type, emailVerifiedAt
  })

  const onSubmit = (data) => {
    const payload = {}
    Object.keys(prevUserData).forEach((key) => {
      if (prevUserData[key] !== data[key]) {
        payload[key] = data[key]
      }
    })

    if (_.isEmpty(payload)) {
      dispatch(setApiSuccessMsg(`No change for user ${profile.email}`))
    } else {
      requestEditUser(updateUser(authUser, id, payload))
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setUser({ ...user, [name]: value })
  }

  const handleCancel = () => {
    setIsEditUser(false)
    dispatch(resetAllMessages())
  }

  const setOptions = () => (['Standard', 'Newsletter', 'Social', 'Enterprise'])

  return (
    <>
      {userResponse.state === 'loading' && <Loading />}

      <form className="form-container form-edit-container" onSubmit={handleSubmit(onSubmit)}>
        <Input
          className="form-input"
          label="First Name"
          name="firstname"
          value={user.firstname || ''}
          onChange={handleChange}
          ref={register}
        />

        <Input
          className="form-input"
          label="Last Name"
          name="lastname"
          value={user.lastname || ''}
          onChange={handleChange}
          ref={register}
        />

        <Input
          className="form-input"
          label="Email Verified At"
          name="emailVerifiedAt"
          value={user.emailVerifiedAt || ''}
          onChange={handleChange}
          ref={register}
          placeholder="Example : 2021-01-01"
        />

        <Input
          className="form-input"
          label="Email"
          name="email"
          value={user.email || ''}
          onChange={handleChange}
          // eslint-disable-next-line no-useless-escape
          ref={register({ required: true, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
        />
        { errors.email && (<p className="form-input-error">{setFormError(errors.email, 'user')}</p>) }

        <Select
          className="form-input"
          label="Type"
          name="type"
          options={setOptions}
          onChange={handleChange}
          value={user.type}
          ref={register}
        />

        <div className="form-btn-container">
          <Button color="primary" size="lg" type="submit">submit</Button>
          <Button color="secondary" size="lg" type="button" onClick={handleCancel}>cancel</Button>
        </div>

      </form>

    </>
  )
}

EditProfile.defaultProps = { setIsEditUser: () => {} }

EditProfile.propTypes = { setIsEditUser: PropTypes.func }

export default EditProfile
