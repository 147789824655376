import React from 'react'
import PropTypes from 'prop-types'

import {
  Form, FormGroup, FormFeedback, Label, Input, UncontrolledPopover,
  PopoverHeader, PopoverBody
} from 'reactstrap'
import { useForm, Controller } from 'react-hook-form'

import style from './style.module.scss'

export const intervalMap = {
  daily: '1',
  weekly: '7',
  monthly: '30',
  quarterly: '90',
  biannually: '182',
  yearly: '365'
}

const PlanForm = ({ formDataForEditing, onValidFormSubmit }) => {
  const {
    control, errors, formState: { isValid }, handleSubmit, watch
  } = useForm({ mode: 'onChange', defaultValues: formDataForEditing ?? {} })

  const onSubmit = (data) => onValidFormSubmit(data)

  return (
    <div className={style.wrapper}>

      <h1 className={style.title}>Create a plan</h1>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.inputGroup}>

          <FormGroup>
            <Label for="planName">
              Plan name
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style={{ marginLeft: '5px', cursor: 'pointer', verticalAlign: 'top' }} viewBox="0 0 24 24" fill="none" stroke="#007bff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-help-circle" id="plan-name-popover"><circle cx="12" cy="12" r="10" /><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" /><line x1="12" y1="17" x2="12.01" y2="17" /></svg>
              <UncontrolledPopover target="plan-name-popover" trigger="hover">
                <PopoverHeader>
                  [Term] [Term Type] [Price] [Currency (if not USD)]
                </PopoverHeader>
                <PopoverBody>
                  <p className={style.popoverBodyHeader}>Term (Select One)</p>
                  <p className={style.popoverBodyText}>Annual, Ad-Free Annual, Monthly, Semester</p>
                  <p className={style.popoverBodyHeader}>Term Type (Select One)</p>
                  <p className={style.popoverBodyText}>
                    Main, Churner, Nurture, Upgrade, Student, Teacher, Pre-Cancellation
                  </p>
                  <p className={style.popoverBodyHeader}>Price</p>
                  <p className={style.popoverBodyText}>Example: 99/159</p>
                  <p className={style.popoverBodyHeader}>Currency (if not USD)</p>
                  <p className={style.popoverBodyText}>Example: (GBP)</p>
                </PopoverBody>
              </UncontrolledPopover>
            </Label>
            <Controller
              control={control}
              defaultValue=""
              name="planName"
              rules={{ required: 'This field is required' }}
              render={({ onBlur, onChange, value }) => (
                <Input
                  id="planName"
                  placeholder="Ex: Annual Churners 99/159 (CAD)"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  invalid={errors.planName}
                />
              )}
            />
            {errors.planName && <FormFeedback>{errors.planName.message}</FormFeedback>}
          </FormGroup>

          <FormGroup>
            <Label for="planProvider">Plan provider</Label>
            <Controller
              control={control}
              defaultValue="stripe"
              name="planProvider"
              render={({ onChange, value }) => (
                <Input type="select" className={style.select} id="planProvider" onChange={onChange} value={value}>
                  <option value="stripe">Stripe</option>
                </Input>
              )}
            />
          </FormGroup>

          <FormGroup>
            <Label for="adFree">Is plan Ad-free?</Label>
            <Controller
              control={control}
              defaultValue="false"
              name="adFree"
              render={({ onChange, value }) => (
                <Input type="select" className={style.select} id="adFree" onChange={onChange} value={value}>
                  <option value="false">No</option>
                  <option value="true">Yes</option>
                </Input>
              )}
            />
          </FormGroup>

          <FormGroup>
            <Label for="planInterval">Plan interval</Label>
            <Controller
              control={control}
              defaultValue="monthly"
              name="planInterval"
              render={({ onChange, value }) => (
                <Input type="select" className={style.select} id="planInterval" onChange={onChange} value={value}>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="quarterly">Quarterly</option>
                  <option value="biannually">Biannually</option>
                  <option value="yearly">Yearly</option>
                </Input>
              )}
            />
          </FormGroup>

          <FormGroup>
            <Label for="validFor">Plan valid for</Label>
            <Input
              disabled
              type="select"
              className={style.select}
              id="validFor"
              value={intervalMap[watch('planInterval')]}
            >
              <option value="1">1 Day</option>
              <option value="7">7 Days</option>
              <option value="30">30 Days</option>
              <option value="90">90 Days</option>
              <option value="182">182 Days</option>
              <option value="365">365 Days</option>
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="planCurrency">Plan currency</Label>
            <Controller
              control={control}
              defaultValue="usd"
              name="planCurrency"
              render={({ onChange, value }) => (
                <Input type="select" className={style.select} id="planCurrency" onChange={onChange} value={value}>
                  <option value="usd">USD</option>
                  <option value="cad">CAD</option>
                  <option value="eur">EUR</option>
                  <option value="gbp">GBP</option>
                </Input>
              )}
            />
          </FormGroup>

          <FormGroup>
            <Label for="planIsPromotion">Is plan a promotion?</Label>
            <Controller
              control={control}
              defaultValue="false"
              name="planIsPromotion"
              render={({ onChange, value }) => (
                <Input type="select" className={style.select} id="planIsPromotion" onChange={onChange} value={value}>
                  <option value="false">No</option>
                  <option value="true">Yes</option>
                </Input>
              )}
            />
          </FormGroup>

          <FormGroup>
            <Label for="planCategory">Plan category</Label>
            <Controller
              control={control}
              defaultValue="initial-acquisition"
              name="planCategory"
              render={({ onChange, value }) => (
                <Input type="select" className={style.select} id="planCategory" onChange={onChange} value={value}>
                  <option value="initial-acquisition">Initial Acquisition</option>
                  <option value="save-offer">Save Offer</option>
                  <option value="upgrade-offer">Upgrade Offer</option>
                  <option value="student-teacher">Student Teacher</option>
                  <option value="churner">Churner</option>
                </Input>
              )}
            />
          </FormGroup>

          <FormGroup>
            <Label for="price1Amount">Price 1 amount</Label>
            <Controller
              control={control}
              defaultValue=""
              name="price1Amount"
              rules={{
                required: 'This field is required',
                pattern: {
                  value: /^[0-9]*\.[0-9]{2}$/,
                  message: 'Must be a valid currency (cents required)'
                }
              }}
              render={({ onBlur, onChange, value }) => (
                <Input
                  type="number"
                  min="0"
                  step="0.01"
                  id="price1Amount"
                  placeholder="Ex. 9.99"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  invalid={!!errors.price1Amount}
                />
              )}
            />
            {errors.price1Amount && <FormFeedback>{errors.price1Amount.message}</FormFeedback>}
          </FormGroup>

          <FormGroup>
            <Label for="price1Type">Price 1 type</Label>
            <Input disabled type="select" className={style.select} id="price1Type" value={watch('price2Amount') ? 'intro' : 'standard'}>
              <option value="intro">Intro</option>
              <option value="standard">Standard</option>
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="price2Amount">
              Price 2 amount <span className={style.optionalText}>(optional)</span>
            </Label>
            <Controller
              control={control}
              defaultValue=""
              name="price2Amount"
              rules={{
                pattern: {
                  value: /^[0-9]*\.[0-9]{2}$/,
                  message: 'Must be a valid currency (cents required)'
                }
              }}
              render={({ onBlur, onChange, value }) => (
                <Input
                  type="number"
                  min="0"
                  step="0.01"
                  id="price2Amount"
                  placeholder="Ex. 9.99"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  invalid={!!errors.price2Amount}
                />
              )}
            />
            {errors.price2Amount && <FormFeedback>{errors.price2Amount.message}</FormFeedback>}
          </FormGroup>

          <FormGroup>
            <Label for="price2Type">
              Price 2 type <span className={style.optionalText}>(optional)</span>
            </Label>
            <Input
              disabled
              type="select"
              className={style.select}
              id="price2Type"
              value={watch('price2Amount') ? 'standard' : ''}
            >
              <option value="">None</option>
              <option value="standard">Standard</option>
            </Input>
          </FormGroup>
        </div>

        <button type="submit" className={style.previewPlanButton} disabled={!isValid}>Preview plan</button>

      </Form>
    </div>
  )
}

PlanForm.defaultProps = {
  formDataForEditing: null
}

PlanForm.propTypes = {
  formDataForEditing: PropTypes.shape({
    planName: PropTypes.string,
    planProvider: PropTypes.string,
    adFree: PropTypes.string,
    planInterval: PropTypes.string,
    planCurrency: PropTypes.string,
    price1Amount: PropTypes.string,
    price2Amount: PropTypes.string
  }),
  onValidFormSubmit: PropTypes.func.isRequired
}

export default PlanForm
